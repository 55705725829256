import './index.css';
import './print.css';
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import SignupPage from './pages/SignupPage';
import DashBoard from './pages/DashBoard';
import Header from './components/Header';
import PrivateRoutes from './auth/PrivateRoutes';
import CreateEditPage from './pages/CreateEditPage';
import WorkLogScreen from './pages/WorkLogScreen';
import Alerts from './components/Alerts';
import Contact from './pages/Contact';
import PasswordReset from './pages/PasswordReset';
import Footer from './components/Footer';

const App = () => {
  return (
    <>
      <div className='wrapper min-h-[95.5vh]'>
        <Alerts />
        <Header />
        <div className='mx-auto container custom-container mt-20 px-3 '>
          <Routes>
            <Route element={<LoginPage />} path='/' />
            <Route element={<SignupPage />} path='/register' />
            <Route element={<PasswordReset />} path='/password-reset' />

            <Route element={<PrivateRoutes />}>
              <Route element={<DashBoard />} path='/dashboard' exact />
              <Route element={<CreateEditPage />} path='/user' exact />
              <Route
                element={<CreateEditPage />}
                path='/user/edit/:employeeId'
                exact
              />
              <Route
                element={<WorkLogScreen />}
                path='/worklog/:employeeId/'
                exact
              />
              <Route element={<Contact />} path='/kontakt' exact />
            </Route>
          </Routes>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default App;
