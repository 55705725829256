import { db } from '../config/config';
import {
  doc,
  collection,
  onSnapshot,
  addDoc,
  updateDoc,
  getDoc,
  deleteDoc,
  setDoc,
  increment,
} from 'firebase/firestore';

import { auth } from '../config/config';
import { createUserWithEmailAndPassword } from 'firebase/auth';

// Update employee

export const updateEmployee = async (
  employeeId,
  employeeData,
  currentUserUid,
  navigate,
  setLoading,
  setAlert
) => {
  try {
    const employeeRef = doc(
      db,
      'users',
      currentUserUid,
      'employees',
      employeeId
    );
    await updateDoc(employeeRef, employeeData);

    setAlert(
      'Zaposlenik je ažuriran',
      'bg-green-100 font-semibold dark:bg-gray-900 dark:text-red-400 font-semibold'
    );

    await navigate('/');
  } catch (e) {
    setAlert(
      'Greška na serveru',
      'text-red-800 bg-red-100 dark:bg-gray-900 dark:text-red-400 font-semibold'
    );

    setLoading(false);
  }
};

export const displayEmployees = (currentUserUid, dispatch) => {
  const userEmployeesRef = collection(db, 'users', currentUserUid, 'employees');

  const unsubscribe = onSnapshot(userEmployeesRef, (snapshot) => {
    const employeeList = [];

    snapshot.forEach((doc) => {
      const employeeData = { id: doc.id, ...doc.data() };
      // Destructure to exclude workingMonths
      const { workingMonths, ...filteredEmployee } = employeeData;
      /*  employeeList.push(employeeData); */
      employeeList.push(filteredEmployee);
    });

    dispatch({ type: 'DISPLAY_EMPLOYEES', payload: employeeList }); // Dispatch employee list
  });

  return unsubscribe; // Return unsubscribe function
};

// Function to get the company name for the current user
export const getCompanyDetails = async (currentUserUid, dispatch) => {
  const userDocRef = doc(db, 'users', currentUserUid); // Reference to the user document

  try {
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      const companyDetails = userDoc.data(); // Retrieve the company name

      dispatch({ type: 'GET_COMPANY_DETAILS', payload: companyDetails }); // Dispatch company name
      return companyDetails; // Return the company name
    } else {
      console.log('No such user document!');
      return null; // Return null if no document found
    }
  } catch (error) {
    console.error('Error fetching company name:', error); // Error handling
    return null; // Return null on error
  }
};

//Register user

// Function to register a new user and add company name
export const registerUser = async (
  email,
  password,
  companyName,
  companyPrice,
  navigate,
  setAlert
) => {
  try {
    // Attempt to register the user
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user; // Get user info

    // Create a user document in Firestore
    await setDoc(doc(db, 'users', user.uid), {
      companyName: companyName,
      companyPrice: companyPrice, // Add company name field
      // You can add other fields here as needed
    });

    setAlert(
      'Korisnik je uspješno registriran',
      'bg-green-100 font-semibold dark:bg-gray-900 dark:text-red-400 font-semibold'
    );

    navigate('/');
    return user.uid; // Return user ID if needed
  } catch (error) {
    // Handle specific error cases
    if (error.code === 'auth/email-already-in-use') {
      setAlert(
        'Email već postoji',
        'text-red-800 bg-red-100 dark:bg-gray-900 dark:text-red-400 font-semibold'
      );
    } else if (error.code === 'auth/weak-password') {
      console.error('Password is too weak. Please choose a stronger password.');
      throw new Error(
        'Password is too weak. Please choose a stronger password.'
      );
    } else {
      console.error('Error registering user:', error.message);
      throw new Error('Error registering user. Please try again.');
    }
  }
};

// add new employee

// Function to add an employee to the collection with reference collection(db, 'users', currentUserUid, 'employees')
export const addEmployee = async (
  employeeData,
  workingMonthsData,
  currentUserUid,
  navigate,
  setAlert
) => {
  try {
    // Reference to the user's document
    const userRef = doc(db, 'users', currentUserUid);
    const userSnapshot = await getDoc(userRef);

    if (!userSnapshot.exists()) {
      throw new Error('User does not exist');
    }

    // Get the user's pricing plan and current employee count
    const userData = userSnapshot.data();
    const { pricingPlan, employeeCount = 0 } = userData;

    // Define employee limits based on pricing plan
    const planLimits = {
      5: 5,
      12: 12,
      25: 25,
      40: 40,
    };
    const maxEmployees = planLimits[pricingPlan] || 5; // Default to 5 if pricingPlan is undefined

    // Check if the current employee count exceeds the allowed limit
    if (employeeCount >= maxEmployees) {
      setAlert(
        'Dostigli ste maksimalan broj zaposlenika za vaš plan.',
        'text-red-800 bg-red-100 dark:bg-gray-900 dark:text-red-400 font-semibold'
      );
      return null;
    }

    // Proceed to add employee if under the limit
    const employeeRef = collection(db, 'users', currentUserUid, 'employees');
    const docRef = await addDoc(employeeRef, employeeData);
    const id = docRef.id;

    // Add working months data
    addWorkingMonths(workingMonthsData, currentUserUid, id, navigate);

    // Update the employee count in the user document
    await setDoc(userRef, { employeeCount: increment(1) }, { merge: true });

    setAlert(
      'Zaposlenik je uspješno dodan',
      'bg-green-100 font-semibold dark:bg-gray-900 dark:text-red-400 font-semibold'
    );

    navigate('/dashboard');

    return docRef.id;
  } catch (e) {
    setAlert(
      'Greška na serveru',
      'text-red-800 bg-red-100 dark:bg-gray-900 dark:text-red-400 font-semibold'
    );
    return null;
  }
};

// Function to add working months to an employee with a specific ID in the collection with reference collection(db, 'users', currentUserUid, 'employees')
export const addWorkingMonths = async (
  workingMonthsData,
  currentUserUid,
  employeeId,
  navigate
) => {
  try {
    const employeeRef = doc(
      db,
      'users',
      currentUserUid,
      'employees',
      employeeId
    );
    await updateDoc(employeeRef, {
      workingMonths: workingMonthsData,
    });
  } catch (e) {
    return null;
  }
};
// Delete eymployee

export const deleteEmployee = async (
  currentUserUid,
  employeeId,
  setAlert,
  dispatch
) => {
  const employeeRef = doc(db, 'users', currentUserUid, 'employees', employeeId);
  const userRef = doc(db, 'users', currentUserUid);

  try {
    // Delete the employee document
    await deleteDoc(employeeRef);
    console.log('Employee deleted successfully!');

    // Get current employee count
    const userDoc = await getDoc(userRef);
    if (userDoc.exists()) {
      const currentCount = userDoc.data().employeeCount || 0;

      // Decrement the count and update it in Firestore
      await updateDoc(userRef, {
        employeeCount: Math.max(currentCount - 1, 0), // Ensure count doesn't go below 0
      });
    }

    setAlert(
      'Zaposlenik je izbrisan',
      'bg-green-100 font-semibold dark:bg-gray-900 dark:text-red-400 font-semibold'
    );
    getCompanyDetails(currentUserUid, dispatch);
  } catch (error) {
    console.error('Error deleting employee or updating count:', error);
    setAlert(
      'Greška na serveru',
      'text-red-800 bg-red-100 dark:bg-gray-900 dark:text-red-400 font-semibold'
    );
  }
};

export const getEmployee = async (currentUserUid, employeeId, dispatch) => {
  try {
    const employeeRef = doc(
      db,
      'users',
      currentUserUid,
      'employees',
      employeeId
    );
    const employeeDoc = await getDoc(employeeRef);

    if (employeeDoc.exists()) {
      return { id: employeeDoc.id, ...employeeDoc.data() };
    } else {
      console.log('No such employee document!');
      return null;
    }
  } catch (e) {
    console.error('Error getting employee document: ', e);
    return null;
  }
};

// display employee by month

export const getMonthByEmployee = async (
  selectedMonth,
  currentUserUid,
  employeeId,
  newMonthData,
  setAlert
) => {
  const employeeRef = doc(db, 'users', currentUserUid, 'employees', employeeId);
  const employeeDoc = await getDoc(employeeRef);

  if (!employeeDoc.exists()) {
    console.log(`Employee with ID '${employeeId}' does not exist`);
    setAlert('Zaposlenik ne postoji');
    return null;
  }

  const employeeData = employeeDoc.data();

  if (!employeeData) {
    console.log(`Employee with ID '${employeeId}' does not have any data`);
    return null;
  }
  const workingMonths = employeeData.workingMonths;

  // If no selectedMonth is provided, get the last month in the workingMonths array
  if (!selectedMonth) {
    const lastMonth = workingMonths[workingMonths.length - 1];
    selectedMonth = lastMonth.month;
  }

  const monthData = workingMonths.find(
    (month) => month.month === selectedMonth
  );
  // Ovdje moze se staviti createMonth jer kad nema mjeseca onda tako moze
  if (!monthData) {
    console.log(
      `Employee with ID '${employeeId}' does not have any data for month '${selectedMonth}'`
    );

    // Call addNewMonthByEmployee and return the result
    return addNewMonthByEmployee(currentUserUid, employeeId, newMonthData).then(
      (result) => {
        // Call getMonthByEmployee again after the inner API call is finished
        return getMonthByEmployee(selectedMonth, currentUserUid, employeeId);
      }
    );
  }

  return {
    employeeId,
    firstName: employeeData.firstName,
    lastName: employeeData.lastName,
    month: monthData.month,
    workingMonths: monthData,
    startDate: employeeData.startDate,
    endDate: employeeData.endDate,
    weekShift: employeeData.weekShift,
  };
};

export const getEmployeeMonths = async (employeeId, currentUserUid) => {
  const employeeRef = doc(db, 'users', currentUserUid, 'employees', employeeId);
  const employeeDoc = await getDoc(employeeRef);
  if (!employeeDoc.exists()) {
    console.log('No such employee document!');
    return [];
  }
  const employeeData = employeeDoc.data();
  const workingMonths = employeeData.workingMonths || [];
  const months = workingMonths.map((workingMonth) => workingMonth.month);
  return months;
};

export const updateMonthByEmployee = async (
  currentUserUid,
  employeeId,
  selectedMonth,
  updatedData,
  setAlert
) => {
  try {
    const employeeRef = doc(
      db,
      'users',
      currentUserUid,
      'employees',
      employeeId
    );
    const employeeDoc = await getDoc(employeeRef);

    if (!employeeDoc.exists()) {
      console.log(`Employee with ID '${employeeId}' does not exist`);
      return null;
    }

    const employeeData = employeeDoc.data();
    const workingMonths = employeeData.workingMonths || [];

    // Find the index of the month to update
    const monthIndex = workingMonths.findIndex(
      (month) => month.month === selectedMonth
    );

    if (monthIndex === -1) {
      console.log(
        `Mjesec '${selectedMonth}' nije pronađen za zaposlenika '${employeeId}'`
      );

      return null;
    }

    // Update the data for the selected month
    workingMonths[monthIndex] = {
      workingDays: [...updatedData],
      month: workingMonths[monthIndex].month,
    };

    // Update the employee document with the modified workingMonths array
    await updateDoc(employeeRef, { workingMonths });

    setAlert(
      'Mjesec je ažuriran',
      'bg-green-100 font-semibold dark:bg-gray-900 dark:text-red-400 font-normal'
    );

    return {
      employeeId,
      firstName: employeeData.firstName,
      lastName: employeeData.lastName,
      workingMonths: workingMonths[monthIndex],
      startDate: employeeData.startDate,
      weekShift: employeeData.weekShift,
    };
  } catch (e) {
    console.error('Error updating employee document: ', e);
    setAlert(
      'Greška na serveru',
      'text-red-800 bg-red-100 dark:bg-gray-900 dark:text-red-400 font-semibold'
    );
    return null;
  }
};

export const addNewMonthByEmployee = async (
  currentUserUid,
  employeeId,
  newMonthData
) => {
  try {
    const employeeRef = doc(
      db,
      'users',
      currentUserUid,
      'employees',
      employeeId
    );
    const employeeDoc = await getDoc(employeeRef);

    if (!employeeDoc.exists()) {
      console.log(`Employee with ID '${employeeId}' does not exist`);
      return null;
    }

    const employeeData = employeeDoc.data();
    const workingMonths = employeeData.workingMonths || [];

    // Check if the month already exists
    const existingMonthIndex = workingMonths.findIndex(
      (month) => month.month === newMonthData.month
    );

    if (existingMonthIndex !== -1) {
      console.log(
        `Month '${newMonthData.month}' already exists for employee '${employeeId}'`
      );
      return null;
    }

    // Add the new month data to the workingMonths array
    workingMonths.push(newMonthData);

    // Update the employee document with the modified workingMonths array
    await updateDoc(employeeRef, { workingMonths });

    return {
      employeeId,
      firstName: employeeData.firstName,
      lastName: employeeData.lastName,
      newMonthData,
      startDate: employeeData.startDate,
      weekShift: employeeData.weekShift,
    };
  } catch (e) {
    console.error('Error adding new month for employee: ', e);
    return null;
  }
};

// Assume updatedDay is the day being updated

// Your onBlur handler
export const updatesDayInFirestore = async (
  userId,
  employeeId,
  selectedMonth, // Use a different variable name to avoid confusion
  updatedDay
) => {
  try {
    const employeeRef = doc(db, 'users', userId, 'employees', employeeId);
    const employeeDoc = await getDoc(employeeRef);

    if (!employeeDoc.exists()) {
      console.log(`Employee with ID '${employeeId}' does not exist`);
      return;
    }

    const employeeData = employeeDoc.data();
    const workingMonths = employeeData.workingMonths || [];

    console.log(workingMonths, selectedMonth);

    // Find the index of the month to update
    const monthIndex = workingMonths.findIndex(
      (month) => month.month === selectedMonth
    );

    if (monthIndex === -1) {
      console.log(
        `Month '${selectedMonth}' not found for employee '${employeeId}'`
      );
      return;
    }

    // Update the specific day in the workingDays array
    const updatedWorkingDays = workingMonths[monthIndex].workingDays.map(
      (day) => (day.date === updatedDay.date ? updatedDay : day)
    );

    // Construct the modified workingMonths array
    const updatedWorkingMonths = [
      ...workingMonths.slice(0, monthIndex),
      {
        month: workingMonths[monthIndex].month,
        workingDays: updatedWorkingDays,
      },
      ...workingMonths.slice(monthIndex + 1),
    ];

    // Update the employee document with the modified workingMonths array
    await updateDoc(employeeRef, { workingMonths: updatedWorkingMonths });

    console.log('Document updated successfully');
  } catch (error) {
    console.error('Error updating employee document: ', error);
  }
};

export const updateDayInFirestore = async (
  userId,
  employeeId,
  month,
  updatedDay
) => {
  try {
    const employeeRef = doc(db, 'users', userId, 'employees', employeeId);
    const employeeDoc = await getDoc(employeeRef);

    if (!employeeDoc.exists()) {
      console.log(`Employee with ID '${employeeId}' does not exist`);
      return;
    }

    const data = employeeDoc.data();

    // Find the index of the month to be updated in the workingMonths array
    const monthIndex = data.workingMonths.findIndex(
      (entry) => entry.month === month
    );

    if (monthIndex !== -1) {
      // Update only the specific day in the workingDays array
      data.workingMonths[monthIndex].workingDays = data.workingMonths[
        monthIndex
      ].workingDays.map((day) => {
        if (day.date === updatedDay.date) {
          // Update only the fields that have changed
          return {
            ...day,
            ...updatedDay,
          };
        }
        return day;
      });

      // Update the document in Firestore with the modified data

      await updateDoc(employeeRef, data);
    } else {
      // Handle the case where the month is not found
      console.error('Month not found');
    }
  } catch (error) {
    console.error('Error updating day in Firestore:', error);
  }
};
