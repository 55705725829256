import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { localhost } from '../config/config';
import axios from 'axios';

const Contact = () => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null); // For displaying success or error messages
  const formik = useFormik({
    initialValues: {
      fullName: '',
      email: '',
      subject: '',
      message: '',
    },
    validationSchema: Yup.object({
      fullName: Yup.string().required('Obavezno polje'),
      email: Yup.string().email('Pogrešan email').required('Obavezno polje'),
      subject: Yup.string().required('Obavezno polje'),
      message: Yup.string().required('Obavezno polje'),
    }),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      // Send the form data to the server
      const response = await axios.post(`${localhost}/submit-form`, values);

      // Check the response status and update the state accordingly
      if (response.status === 200) {
        setLoading(false);
        setMessage({
          type: 'success',
          message: `Hvala, ${values.fullName}, kontaktirat ćemo vas uskoro!`,
        });
        formik.resetForm(); // Reset the form fields
      } else {
        throw new Error('Error sending email');
      }
    } catch (error) {
      setLoading(false);
      console.error(error);

      setMessage({
        type: 'error',
        message: 'Geška prilikom slanja podataka!',
      });
    }

    setTimeout(() => {
      setMessage(null); // Clear the message after a while
    }, 5000); // Adjust the timeout as needed
  };

  const errorStyle = {
    color: '#FF0000',
    fontSize: '12px',
    marginLeft: '10px',
    marginTop: '5px',
  };

  return (
    <div className='flex py-5 justify-center'>
      <div className='w-50 p-6 flex flex-col items-center justify-center'>
        <div className='w-[400px] h-[200px] bg-white shadow rounded-lg flex flex-col items-center justify-center'>
          <h2 className='text-2xl font-semibold border-b-2 border-gray-300 pb-2 mb-5'>
            Kontaktirajte nas
          </h2>
          <p className='text-xl font-normal text-gray-700'>
            Broj: <span className='font-bold text-gray-900'>061 056 710</span>
          </p>
        </div>
      </div>
      <div className='p-4 w-50'>
        {message && (
          <div
            className={`mb-4 p-4 rounded-lg ${
              message.type === 'success'
                ? 'bg-green-100 text-green-700'
                : 'bg-red-100 text-red-700'
            }`}
          >
            {message.message}
          </div>
        )}
        <form
          onSubmit={formik.handleSubmit}
          className='max-w-lg mx-auto p-6 bg-white rounded-lg border border-[#eee]'
        >
          <h2 className='text-2xl font-normal text-center mb-5 font-sans'>
            Pošaljite upit
          </h2>

          <div className='flex mb-4 mt-3'>
            <div className='w-1/2 mr-4'>
              <label
                htmlFor='fullName'
                className='block mb-1 font-medium text-gray-700 '
              >
                Ime i Prezime:
              </label>
              <input
                id='fullName'
                type='text'
                className='w-full px-4 py-2 border rounded-lg focus:border-blue-500 focus:outline-none transition duration-200'
                {...formik.getFieldProps('fullName')}
              />
              {formik.touched.fullName && formik.errors.fullName && (
                <div className='text-red-600 text-sm mt-1'>
                  {formik.errors.fullName}
                </div>
              )}
            </div>

            <div className='w-1/2'>
              <label
                htmlFor='email'
                className='block mb-1 font-medium text-gray-700 font-sans'
              >
                Email:
              </label>
              <input
                id='email'
                type='email'
                className='w-full px-4 py-2 border rounded-lg focus:border-blue-500 focus:outline-none transition duration-200'
                {...formik.getFieldProps('email')}
              />
              {formik.touched.email && formik.errors.email && (
                <div className='text-red-600 text-sm mt-1'>
                  {formik.errors.email}
                </div>
              )}
            </div>
          </div>

          <div className='mb-4'>
            <label
              htmlFor='subject'
              className='block mb-1 font-medium text-gray-700 font-sans'
            >
              Predmet:
            </label>
            <input
              id='subject'
              type='text'
              className='w-full px-4 py-2 border rounded-lg focus:border-blue-500 focus:outline-none transition duration-200'
              {...formik.getFieldProps('subject')}
            />
            {formik.touched.subject && formik.errors.subject && (
              <div className='text-red-600 text-sm mt-1'>
                {formik.errors.subject}
              </div>
            )}
          </div>

          <div className='mb-4'>
            <label
              htmlFor='message'
              className='block mb-1 font-medium text-gray-700'
            >
              Poruka:
            </label>
            <textarea
              rows={5}
              id='message'
              className='w-full px-4 py-2 border rounded-lg focus:border-blue-500 focus:outline-none transition duration-200'
              {...formik.getFieldProps('message')}
            />
            {formik.touched.message && formik.errors.message && (
              <div className='text-red-600 text-sm mt-1'>
                {formik.errors.message}
              </div>
            )}
          </div>

          <button
            type='submit'
            className={`w-full bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:outline-none transition duration-200 ${
              loading ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            disabled={loading} // Disable button while loading
          >
            {loading ? 'Slanje...' : 'Pošalji'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
