import React, { useContext, useEffect } from 'react';

import ZaposleniciTable from '../components/ZaposleniciTable';
import authContext from '../contexts/auth/authContext';
import { auth } from '../config/config';
import globalContext from '../contexts/satnica/globalContext';
import Loader from '../components/Loader';

const DashBoard = () => {
  const AuthContext = useContext(authContext);
  const GlobalContext = useContext(globalContext);
  const { user } = AuthContext;

  const { displayEmployees, dispatch, getCompanyDetails, companyDetails } =
    GlobalContext;

  const { companyName } = companyDetails;

  const currentUserUid = auth.currentUser.uid;

  // Display users from firebase
  useEffect(() => {
    if (user) {
      displayEmployees(currentUserUid, dispatch);
      getCompanyDetails(currentUserUid, dispatch);
      dispatch({ type: 'CLEAR_EMPLOYEE' });
    }
    //eslint-disable-next-line
  }, [currentUserUid]);

  return (
    <div className='px-5 pt-5 pb-10'>
      {!companyName ? (
        <Loader />
      ) : (
        <>
          <h3 className='my-5 font-semibold py-2'>Zaposlenici</h3>
          <div className='flex'>
            <ZaposleniciTable />
          </div>
        </>
      )}
    </div>
  );
};

export default DashBoard;
