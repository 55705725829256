import React from 'react';
import Signup from '../components/Signup';

const SignupPage = () => {
  return (
    <div className='h-screen flex flex-col py-12 px-4 sm:px-6 lg:px-8 md:w-[30rem] m-auto'>
      <div className='mb-10'>
        <h2 className='mt-6 text-center text-3xl text-gray-900 font-sans font-normal'>
          Registriraj se
        </h2>
      </div>
      <Signup />
    </div>
  );
};

export default SignupPage;
