import { Link } from 'react-router-dom';

export default function FormExtra() {
  return (
    <div className='flex items-center justify-between mt-0'>
      <div className='text-sm'>
        <Link
          to='/password-reset'
          className='font-medium text-blue-500 hover:text-blue-500'
        >
          Zaboravio si password?
        </Link>
      </div>
    </div>
  );
}
