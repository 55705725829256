import React, { useState, useContext } from 'react';
import moment from 'moment';

import * as yup from 'yup';
import { useFormik } from 'formik';
import WeekDayTable from '../components/WeekDayTable';
import { useEffect } from 'react';
import { auth } from '../config/config';
import { useNavigate, useParams } from 'react-router-dom';
import globalContext from '../contexts/satnica/globalContext';
import 'react-datepicker/dist/react-datepicker.css';
import Loader from '../components/Loader';
import alertContext from '../contexts/alert/alertContext';

const inputStyle =
  'appearance-none  w-[15rem] px-3  border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 text-sm p-1 ';

const CreateEditPage = () => {
  const [todayDate, setTodayDate] = useState('');
  const [startWorkDate, setStartWorkDate] = useState('');
  const [endWorkDate, setEndWorkDate] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const AlertContext = useContext(alertContext);

  const { setAlert } = AlertContext;

  const [loading, setLoading] = useState(false);

  const [workingDaysOfMonth, setWorkingDaysOfMonth] = useState([
    {
      month: '',
      workingHours: [],
    },
  ]);

  const [workingMonthsInYear, setWorkingMonthsInYear] = useState([{}]);

  const param = useParams();

  const { employeeId } = param;

  useEffect(() => {
    setTodayDate(moment().format('YYYY-MM-DD'));
    dispatch({ type: 'DISPLAY_EMPLOYEE', payload: employee });
    setLoading(false);
    //eslint-disable-next-line
  }, []);

  const firstDayOfPreviousMonth = moment()
    .subtract(1, 'month')
    .startOf('month')
    .format('YYYY-MM-DD');

  const lastDayOfCurrentMonth = moment().endOf('month').format('YYYY-MM-DD');

  const navigate = useNavigate();

  const GlobalContext = useContext(globalContext);

  const { addEmployee, dispatch, employee, updateEmployee } = GlobalContext;

  const currentUserUid = auth.currentUser.uid;

  const formik = useFormik({
    initialValues: {
      firstName: employee.firstName ?? '',
      lastName: employee.lastName ?? '',
      gender: employee.gender ?? 'muško',
      department: employee.department ?? '',
      address: employee.address ?? '',
      startDate: employee.startDate ?? '',
      endDate: employee.endDate ?? '',
      group: employee.group ?? 'radnici',
      weekShift: employee.weekShift ?? '',
    },

    validationSchema: yup.object().shape({
      firstName: yup.string().required('Ime je obavezno'),
      lastName: yup.string().required('Prezime je obavezno'),
      gender: yup.string(),
      department: yup.string(),
      address: yup.string(),
      startDate: yup.string().required('Početak rada je obavezan'),
      endDate: yup.string(),
      group: yup.string(),
      weekShift: yup
        .number()
        .required('Unesite broj sati')
        .positive('Mora biti pozitivan')
        .typeError('Unesite broj'),
    }),

    onSubmit: async (values) => {
      setLoading(true);

      // Set endDate based on selectedOptions
      const finalValues = {
        ...values,
        endDate: selectedOptions.length > 0 ? values.endDate : '',
      };

      try {
        if (employee && employeeId) {
          await updateEmployee(
            employeeId,
            finalValues,
            currentUserUid,
            navigate,
            setLoading,
            setAlert
          );
        } else {
          await addEmployee(
            values,
            workingMonthsInYear,
            currentUserUid,
            navigate,
            setAlert
          );
        }
      } catch (error) {
        console.error('Error submitting form:', error);
      } finally {
        setLoading(false); // Reset loading to false after API calls
      }
    },
  });

  const handleOptionChange = (event) => {
    const value = event.target.value;
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedOptions([value]);
    } else {
      setSelectedOptions(selectedOptions.filter((option) => option !== value));
    }
  };

  const handleDateChange = (event) => {
    const date = event.target.value;
    formik.handleChange(event); // call Formik's handleChange function to update the formik.values object
    setStartWorkDate(new Date(date));
  };
  const handleEndDate = (event) => {
    const date = event.target.value;
    formik.handleChange(event); // call Formik's handleChange function to update the formik.values object
    setEndWorkDate(new Date(date));
  };

  const errorStyle = {
    color: '#FF0000',
    fontSize: '12px',
    marginLeft: '10px',
    marginTop: '5px',
  };

  useEffect(() => {
    if (employee && employee?.endDate !== '') {
      setSelectedOptions(['kraj evidencije']);
    } else {
      setEndWorkDate('');
      setSelectedOptions([]);
    }
  }, []);

  const lastWorkingMonth =
    employee?.workingMonths?.[employee.workingMonths.length - 1]?.month;

  // Format the last working month to YYYY-MM-DD using Moment.js
  const maxDate = lastWorkingMonth
    ? moment(lastWorkingMonth).startOf('month').format('YYYY-MM-DD')
    : moment(lastWorkingMonth).format('YYYY-MM-DD');

  return (
    <div className='mb-10'>
      <h3 className='my-10 font-semibold p-2 '>
        Zaposlenici {'>'}{' '}
        {employeeId ? 'Ažuriraj Zaposlenika' : 'Dodaj Zaposlenika'}
      </h3>

      <form className='mt-10 p-2 ' onSubmit={formik.handleSubmit}>
        <div>
          <div className='font-semibold mb-10'>
            <h3 className='border-b-2 border-solid border-black inline-block'>
              Osnovni Podaci
            </h3>
          </div>

          <div className='flex items-center mb-3 text-sm'>
            <label className='my-2 mr-2 w-[4rem]'>Ime</label>
            <input
              type='text'
              name='firstName'
              className={inputStyle}
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <span className='ml-1'>*</span>
            <div style={errorStyle}>
              {formik.touched.firstName &&
                formik.errors.firstName &&
                formik.errors.firstName}
            </div>
          </div>
          <div className='flex items-center mb-3 text-sm'>
            <label className='my-2 mr-2 w-[4rem]'>Prezime</label>
            <input
              type='text'
              name='lastName'
              className={inputStyle}
              onChange={formik.handleChange}
              value={formik.values.lastName}
              onBlur={formik.handleBlur}
            />
            <span className='ml-1'>*</span>
            <div style={errorStyle}>
              {formik.touched.lastName &&
                formik.errors.lastName &&
                formik.errors.lastName}
            </div>
          </div>
          <div className='flex items-center mb-3 text-sm'>
            <label className='my-2 mr-2 w-[4rem]'>Spol</label>
            <select
              name='gender'
              className='p-1 px-3 text-sm w-[10rem]'
              onChange={formik.handleChange}
              value={formik.values.gender}
            >
              <option defaultValue='muško'>muško</option>
              <option value='žensko'>žensko</option>
            </select>
          </div>
          <div className='flex items-center mb-3 text-sm'>
            <label className='my-2 mr-2 w-[4rem]'>Radno mjesto</label>
            <input
              type='text'
              name='department'
              className={inputStyle}
              value={formik.values.department}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className='flex items-center mb-3 text-sm'>
            <label className='my-2 mr-2 w-[4rem]'>Adresa</label>
            <input
              type='text'
              name='address'
              className={inputStyle}
              value={formik.values.address}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className='flex items-center mb-3 text-sm'>
            <label className='my-2 mr-2 w-[4rem]'>Grupa</label>
            <select
              name='group'
              className='p-1 text-sm w-[12rem] px-3'
              onChange={formik.handleChange}
              defaultValue={formik.values.group}
              onBlur={formik.handleBlur}
            >
              <option defaultValue='radnici'>radnici</option>

              <option value='studenti'>studenti</option>
              <option value='stručno osposobljavanje'>
                stručno osposobljavanje
              </option>
            </select>
          </div>

          <div className='flex items-center mb-3 text-sm'>
            <label className='my-2 mr-2 w-[4rem]'>Početak evidencije</label>
            {
              <input
                type='date'
                name='startDate'
                min={firstDayOfPreviousMonth}
                max={todayDate}
                locale='bs-BA'
                disabled={employeeId ? true : false}
                className={inputStyle}
                onChange={handleDateChange}
                value={formik.values.startDate}
                onBlur={formik.handleBlur}
              />
            }

            <span className='ml-1'>*</span>
            <div style={errorStyle}>
              {formik.touched.startDate &&
                formik.errors.startDate &&
                formik.errors.startDate}
            </div>
          </div>
        </div>
        {employeeId && (
          <div>
            <div className='flex my-5 '>
              <label className='mr-2 cursor-pointer  text-sm'>
                <input
                  className='mr-2  text-sm'
                  type='checkbox'
                  value='kraj evidencije'
                  checked={selectedOptions.includes('kraj evidencije')}
                  onChange={handleOptionChange}
                />
                Zaključi radnika
              </label>
            </div>
            {selectedOptions.includes('kraj evidencije') && (
              <div className='flex items-center mb-3 text-sm'>
                <label className='my-2 mr-2 w-[4rem]'>Kraj Evidencije</label>
                {
                  <input
                    type='date'
                    name='endDate'
                    min={
                      employee.startDate < firstDayOfPreviousMonth
                        ? firstDayOfPreviousMonth
                        : employee.startDate
                    }
                    max={maxDate}
                    locale='bs-BA'
                    className={inputStyle}
                    onChange={handleEndDate}
                    value={formik.values.endDate}
                    onBlur={formik.handleBlur}
                  />
                }
                <div style={errorStyle}>
                  {formik.touched.endDate &&
                    formik.errors.endDate &&
                    formik.errors.endDate}
                </div>
              </div>
            )}
          </div>
        )}
        <div>
          <div className='font-semibold mt-10 mb-5'>
            <h3 className='border-b-2 border-solid border-black inline-block'>
              Radno Vrijeme
            </h3>
          </div>
          <div className='flex items-center mb-3 text-sm'>
            <label className='my-2 mr-2'>Sedmična satnica radnika:</label>
            <input
              type='number'
              inputMode='numeric'
              name='weekShift'
              className={inputStyle + 'w-[50px]'}
              onChange={formik.handleChange}
              defaultValue={formik.values.weekShift}
              onBlur={formik.handleBlur}
            />
            <span className='ml-1'>*</span>
            <div style={errorStyle}>
              {formik.touched.weekShift &&
                formik.errors.weekShift &&
                formik.errors.weekShift}
            </div>
          </div>

          <div>
            <WeekDayTable
              inputStyle={inputStyle}
              month={startWorkDate}
              setMonth={setStartWorkDate}
              setWorkingDaysOfMonth={setWorkingDaysOfMonth}
              setWorkingMonthsInYear={setWorkingMonthsInYear}
              workingMonthsInYear={workingMonthsInYear}
            />
          </div>
        </div>
        <div className='flex items-center'>
          <button
            className={`mt-5 p-1 px-5 bg-gray-500 text-white hover:bg-blue-700 hover:text-white ${
              loading ? `opacity-70` : ''
            }`}
            type='submit'
            disabled={loading}
          >
            {employeeId ? 'Ažuriraj Zaposlenika' : 'Dodaj Zaposlenika'}
          </button>
          <div className='mt-4 ml-4'>{loading && <Loader />}</div>
        </div>
      </form>
    </div>
  );
};

export default CreateEditPage;
