import React from 'react';
import { Link } from 'react-router-dom';
import { LogoutButton } from './LogoutButton';

const Navbar = () => {
  return (
    <ul className='flex items-center'>
      {/*   <li className='mr-2'>
        <Link to='/upute'>Upute</Link>
      </li>
 */}
      <li className='mr-2'>
        <Link to='/kontakt'>Kontakt</Link>
      </li>
      <li>
        <LogoutButton />
      </li>
    </ul>
  );
};

export default Navbar;
